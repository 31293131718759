.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.loader {
  position: relative;
  width: 20px;
  height: 20px;
}

.loader::before {
  content: "";
  box-sizing: border-box;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 3px solid #f3f3f3; /* Adjust border thickness */
  border-top: 3px solid #3498db; /* Adjust border color */
  border-radius: 50%;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
.info-window {
  padding: 10px; /* Adjust padding as needed */
  max-width: 300px; /* Adjust max-width as needed */
}

.info-window .gm-ui-hover-effect {
  display: none; /* Hide close button */
}
.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

.switch input { 
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}

input:checked + .slider {
  background-color: #2196F3;
}

input:focus + .slider {
  box-shadow: 0 0 1px #2196F3;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

/* Add these styles in your CSS file or component */
.react-datepicker-wrapper {
  display: inline-block;
  width: 100%;
}

.react-datepicker__input-container {
  width: 100%;
}

.react-datepicker {
  font-family: inherit;
  border: 0;
  border-radius: 0.375rem;
  background-color: #333;
  color: #fff;
}

.react-datepicker__header {
  background-color: #555;
  border-bottom: 0;
}

.react-datepicker__current-month {
  color: #fff;
}

.react-datepicker__day-name, .react-datepicker__day, .react-datepicker__time-name {
  color: #fff;
}

.react-datepicker__day--selected {
  background-color: #555;
  color: #fff;
}

.react-datepicker__day--keyboard-selected {
  background-color: #777;
  color: #fff;
}

.react-datepicker__day:hover {
  background-color: #444;
  color: #fff;
}


.toggle-button-cover {
  display: table-cell;
  position: relative;
  width: 200px;
  height: 140px;
  box-sizing: border-box;
}

.button-cover {
  height: 100px;
  margin: 20px;
  background-color: #fff;
  box-shadow: 0 10px 20px -8px #c5d6d6;
  border-radius: 4px;
}

.button-cover:before {
  counter-increment: button-counter;
  content: counter(button-counter);
  position: absolute;
  right: 0;
  bottom: 0;
  color: #d7e3e3;
  font-size: 12px;
  line-height: 1;
  padding: 5px;
}

.button-cover,
.knobs,
.layer {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.button {
  position: relative;
  top: 50%;
  width: 110px;
  height: 40px;
  margin: 0px auto 0 auto;
  overflow: hidden;
  
}

.button.r,
.button.r .layer {
  border-radius: 100px;
}

.button.b2 {
  border-radius: 40px;
}

.checkbox {
  position: relative;
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
  opacity: 0;
  cursor: pointer;
  z-index: 3;
}

.knobs {
  z-index: 2;
}

.layer {
  width: 100%;
  background-color: #ebf7fc;
  transition: 0.3s ease all;
  z-index: 1;
}

/* Button 16 */
#button-16 .knobs:before {
  content: "Graph";
  position: absolute;
  top: 5px;
  left: 4px;
  width: 64px;
  height: 32px;
  color: #fff;
  font-size: 15px;
  font-weight: bold;
  text-align: center;
  line-height: 1;
  padding: 9px 4px;
  background-color: #03a9f4;
  border-radius: 40px;
  transition: 0.3s ease all, left 0.3s cubic-bezier(0.18, 0.89, 0.35, 1.15);
}

#button-16 .checkbox:active + .knobs:before {
  width: 46px;
}

#button-16 .checkbox:checked:active + .knobs:before {
  margin-left: -26px;
}

#button-16 .checkbox:checked + .knobs:before {
  content: "Map";
  left: 40px;
  background-color: #f44336;
}

#button-16 .checkbox:checked ~ .layer {
  background-color: #fcebeb;
}
